import React from "react";
import { Link } from "gatsby";
import { HiMail } from "react-icons/hi";
import { AiFillGithub } from "react-icons/ai";
import { BsFacebook, BsYoutube, BsLinkedin, BsTwitter } from "react-icons/bs";
import Subscribe from "../subscribe";
import {StaticImage} from "gatsby-plugin-image";
import CustomLink from "../custom-link";

export default function Footer({ slug }) {
    return (
        <div className="">
            <div className="sticky bottom-0 left-0 right-0 px-4 sm:px-6 xl:px-0 max-w-[1024px] mx-auto mt-8 md:mt-16 pb-8 text-footerLogo dark:text-gray-200">
                <div className={'flex justify-center'}>
                    <Link aria-label="LetsTravel247" to="/" className={'block'}>
                        <StaticImage
                            alt={'Lets Travel 247'}
                            height={60}
                            src={'../../images/logo.png'}
                        />
                    </Link>
                </div>
                <div className="mb-3">
                    <Subscribe />
                </div>
                {/* <div className="flex justify-center items-center mb-3">
                <Link to="" className="px-2">
                    <HiMail className=" w-6 h-6 hover:text-blue-400" />
                </Link>
                <Link to="" className="px-2">
                    <AiFillGithub className=" w-6 h-6 hover:text-blue-400" />
                </Link>
                <Link to="" className="px-2">
                    <BsFacebook className=" w-6 h-6 hover:text-blue-400" />
                </Link>
                <Link to="" className="px-2">
                    <BsYoutube className=" w-6 h-6 hover:text-blue-400" />
                </Link>
                <Link to="" className="px-2">
                    <BsLinkedin className=" w-6 h-6 hover:text-blue-400" />
                </Link>
                <Link to="" className="px-2">
                    <BsTwitter className=" w-6 h-6 hover:text-blue-400" />
                </Link>
            </div> */}
                <div className="">
                    {/*<ul className="block md:hidden my-6">*/}
                    {/*    <li className=" list-none py-2">*/}
                    {/*        <Link*/}
                    {/*            className="text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary border-b"*/}
                    {/*            to="/resources"*/}
                    {/*        >*/}
                    {/*            Resources*/}
                    {/*        </Link>*/}
                    {/*    </li>*/}
                    {/*    <li className=" list-none py-2">*/}
                    {/*        <Link*/}
                    {/*            className="text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary border-b"*/}
                    {/*            to="/partners"*/}
                    {/*        >*/}
                    {/*            Partners*/}
                    {/*        </Link>*/}
                    {/*    </li>*/}
                    {/*    <li className=" list-none py-2">*/}
                    {/*        <Link*/}
                    {/*            className="text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary border-b"*/}
                    {/*            to="/newsletter"*/}
                    {/*        >*/}
                    {/*            Newsletter*/}
                    {/*        </Link>*/}
                    {/*    </li>*/}
                    {/*    <li className=" list-none py-2">*/}
                    {/*        <Link*/}
                    {/*            className="text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary border-b"*/}
                    {/*            to="/contact"*/}
                    {/*        >*/}
                    {/*            Contact*/}
                    {/*        </Link>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                    <div className="flex justify-center items-center my-6">
                        <Link
                            className="text-primary dark:text-white dark:hover:text-secondary hover:text-secondary mx-3"
                            to="/resources"
                            activeClassName={'text-secondary underline'}
                        >
                            Resources
                        </Link>
                        <span className="text-secondary">&nbsp; • &nbsp;</span>
                        <Link
                            className="text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary mx-3"
                            to="/partners"
                            activeClassName={'text-secondary underline'}
                        >
                            Partners
                        </Link>
                        <span className="text-secondary">&nbsp; • &nbsp;</span>
                        <Link
                            className="text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary mx-3"
                            to="/newsletter"
                            activeClassName={'text-secondary underline'}
                        >
                            Newsletter
                        </Link>
                        <span className="text-secondary">&nbsp; • &nbsp;</span>
                        <Link
                            className="text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary mx-3"
                            to="/contact"
                            activeClassName={'text-secondary underline'}
                        >
                            Contact
                        </Link>
                    </div>
                    <p className=" text-xs sm:text-sm font-normal text-center pb-2 text-writing">
                        © 2023&nbsp;
                        <Link
                            className="text-secondary"
                            to="/"
                            activeClassName={'underline'}
                        >
                            LetsTravel247
                        </Link>.
                        All Rights Reserved. Developed by
                        <a
                            href="https://akcybex.com"
                            className="text-secondary"
                        >
                            &nbsp;AKCybex
                        </a>.
                    </p>
                </div>
                {/* <div className="text-center text-writing">
                <p className="text-xs sm:text-sm font-normal">
                    Tailwind Nextjs Theme
                </p>
            </div> */}
            </div>
        </div>
    );
}
