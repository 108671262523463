import React from "react";
import { Link } from "gatsby";

export default function Subscribe() {
    return (
        <div className="w-full sm:max-w-[390px] mx-auto">
            <Link
                to="/newsletter"
                className="pt-4 relative cursor-pointer bounce-container z-50"
            >
                <div className="border border-primary text-primary hover:border-secondary hover:text-secondary rounded-md w-full dark:text-gray-300 px-4 py-2 bg-transparent">
                    Subscribe to our Newsletter
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-arrow-right absolute top-[9px] right-3 -z-10"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        id="mainIconPathAttribute"
                        strokeWidth="1"
                        stroke="#c5454f"
                    ></path>
                </svg>
            </Link>
        </div>
    );
}
