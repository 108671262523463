import React, {useState} from "react";
import {Link} from "gatsby";
import {ThemeToggler} from "gatsby-plugin-dark-mode";
import {BsFillBrightnessHighFill, BsFillMoonFill} from "react-icons/bs";
import {HiMenu} from "react-icons/hi";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import Logo from '../../images/logo.png';

export default function Header() {
	const [showMe, setShowMe] = useState(false);
	const [darkMode, setDarkMode] = useState(false);

	function toggle() {
		setShowMe(!showMe);
	}

	const view = () => {
		setShowMe(false);
	};
	return (
		<div className="mx-auto max-w-[1024px]">
			<nav className="flex justify-between py-6 md:py-10 px-4 sm:px-6 xl:px-0">
				<Link aria-label="LetsTravel247" to="/" className={'-ml-3'}>
					<StaticImage
						alt={'Lets Travel 247'}
						height={60}
						src={'../../images/logo.png'}
					/>
				</Link>

				<div className="md:flex justify-end items-center">
					<div className="hidden md:flex justify-center items-center">
						<Link
							className="mx-[10px] lg:mx-[18px] text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary"
							to="/blog"
							activeClassName={'text-secondary underline'}
						>
							Blog
						</Link>
						<Link
							className="mx-[10px] lg:mx-[18px] text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary"
							to="/categories"
							activeClassName={'text-secondary underline'}
						>
							Categories
						</Link>
						<Link
							className="mx-[10px] lg:mx-[18px] text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary"
							to="/tags"
							activeClassName={'text-secondary underline'}
						>
							Tags
						</Link>
						<Link
							className="mx-[10px] lg:mx-[18px] text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary"
							to="/resources"
							activeClassName={'text-secondary underline'}
						>
							Resources
						</Link>
						<Link
							className="mx-[10px] lg:mx-[18px] text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary"
							to="/partners"
							activeClassName={'text-secondary underline'}
						>
							Partners
						</Link>
						<Link
							className="mx-[10px] lg:mx-[18px] text-primary dark:text-gray-200 dark:hover:text-secondary hover:text-secondary"
							to="/contact"
							activeClassName={'text-secondary underline'}
						>
							Contact
						</Link>
					</div>
					<div className="hidden md:flex justify-center items-center">
						<ThemeToggler>
							{({theme, toggleTheme}) => (
								<button
									className="mx-4 dark:text-white hover:text-secondary dark:hover:text-secondary"
									type="button"
									aria-label="Theme Toggler"
									onClick={() => {
										toggleTheme(
											darkMode ? "light" : "dark"
										);
										setDarkMode(!darkMode);
									}}
								>
									{!darkMode ? (
										<BsFillBrightnessHighFill
											className={"w-5 h-5"}
										/>
									) : (
										<BsFillMoonFill className={"w-5 h-5"}/>
									)}
								</button>
							)}
						</ThemeToggler>
					</div>
				</div>
				<div className="flex justify-center items-center md:hidden">
					<div className="flex md:hidden justify-center items-center">
						<ThemeToggler>
							{({theme, toggleTheme}) => (
								<button
									className="mx-4 dark:text-white hover:text-secondary dark:hover:text-secondary"
									type="button"
									aria-label="Theme Toggler"
									onClick={() => {
										toggleTheme(
											darkMode ? "light" : "dark"
										);
										setDarkMode(!darkMode);
									}}
								>
									{!darkMode ? (
										<BsFillBrightnessHighFill
											className={"w-5 h-5"}
										/>
									) : (
										<BsFillMoonFill className={"w-5 h-5"}/>
									)}
								</button>
							)}
						</ThemeToggler>
					</div>
					<div className="flex justify-center items-center">
						<button
							onClick={toggle}
							className=" text-2xl text-primary dark:text-gray-200"
						>
							<HiMenu/>
							<span className="sr-only">Menu Dropdown</span>
						</button>
					</div>
				</div>
			</nav>
			<div
				style={{
					height: showMe ? "auto" : "0px",
					padding: showMe ? "24px" : "0px",
					visibility: showMe ? "visible" : "hidden",
					transition:
						"visibility 0.5s ease-in-out -0.5s, height 0.5s ease-in-out 0.2s, padding 0.4s ease-in-out 0s",
				}}
				className="bg-secondary mx-4 rounded-md transition-all ease-in-out"
			>
				<div className="flex flex-col">
					<div className="py-2">
						<Link
							className="mx-4 text-gray-200 dark:text-gray-200 dark:hover:text-black hover:text-black"
							to="/blog"
							onClick={view}
							activeClassName={'underline'}
						>
							Blog
						</Link>
					</div>
					<div className="py-2">
						<Link
							className="mx-4 text-gray-200 dark:text-gray-200 dark:hover:text-black hover:text-black"
							to="/categories"
							onClick={view}
							activeClassName={'underline'}
						>
							Categories
						</Link>
					</div>
					<div className="py-2">
						<Link
							className="mx-4 text-gray-200 dark:text-gray-200 dark:hover:text-black hover:text-black"
							to="/tags"
							onClick={view}
							activeClassName={'underline'}
						>
							Tags
						</Link>
					</div>
					<div className="py-2">
						<Link
							className="mx-4 text-gray-200 dark:text-gray-200 dark:hover:text-black hover:text-black"
							to="/resources"
							onClick={view}
							activeClassName={'underline'}
						>
							Resources
						</Link>
					</div>
					<div className="py-2">
						<Link
							className="mx-4 text-gray-200 dark:text-gray-200 dark:hover:text-black hover:text-black"
							to="/newsletter"
							onClick={view}
							activeClassName={'underline'}
						>
							Newsletter
						</Link>
					</div>
					<div className="py-2">
						<Link
							className="mx-4 text-gray-200 dark:text-gray-200 dark:hover:text-black hover:text-black"
							to="/contact"
							onClick={view}
							activeClassName={'underline'}
						>
							Contact
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
