import React from "react";
import Header from "./header";
import Footer from "./footer";

export default function Index({ children }) {
    return (
        <div className="min-h-screen">
            <Header />
            <div>{children}</div>
            <Footer />
        </div>
    );
}
